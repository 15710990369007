.container.order-form {
    margin-bottom: 65px;
    .form-wrapper {
        width: 100%;
        h1 {
            font-weight: 300;
            margin-top: 0;
        }
    }
}
