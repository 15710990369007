@import '../../../../../../Styles/vars';
.order-no-section {
    border-left: 4px solid $error;
    padding: 4px 0 4px 16px;
    .order-ref {
        color: $error;
        font-size: 22px;
        font-weight: 600;
    }
}
