@import '../../../../../../../../../../../../Styles/vars';
/* Customize the label (the container) */

.radio-container {
    display: block;
    position: relative;
    padding-left: 18px;
    cursor: pointer;
    color: $label-color;
    font-size: 16px;
    font-weight: 600;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.radio-container input:disabled {
    color: $done;
}

/* Hide the browser's default radio button */

.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */

.radio-checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 12px;
    width: 12px;
    border: 1px solid $primary;
    background-color: $white;
    border-radius: 50%;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.radio-checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */

.radio-container input:checked ~ .radio-checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */

.radio-container .radio-checkmark:after {
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $primary;
}

.radio-container {
    &.disabled {
        color: $done;
        cursor: default;
        .radio-checkmark {
            border-color: $done;
        }
        .radio-checkmark:after {
            background-color: $done;
        }
    }
}
