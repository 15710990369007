@import '../../../../../../Styles/vars';
.page-info {
    margin-top: 17px;
    grid-column: 2;
    grid-row: 2;
    justify-self: end;
    text-align: right;
    opacity: 0.5;
    color: $dark-violet-text;
    font-size: 16px;
    span:last-child {
        font-size: 14px;
    }
    &.first-row {
        grid-row: 1;
    }
}
