@import '../../Styles/vars';
.button {
    border: $default-border;
    border-radius: $border-radius;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Source Sans Pro', sans-serif;
    height: 40px;
    padding: 0 20px 0 20px;
    cursor: pointer;
    &:active {
        box-shadow: 0 0 3px 2px rgba(0, 113, 188, 0.5);
    }
    &:focus {
        outline: none;
    }
    &.primary {
        background-color: $primary;
        color: $white;
    }
    &.primary:hover {
        background-color: $grayed-primary;
    }
    &.primary:disabled {
        background-color: $done;
        cursor: default;
    }
    &.secondary {
        background-color: $white;
        color: $primary;
    }
    &.secondary:hover {
        background-color: $grey;
    }
    &.secondary:active {
        background-color: $grey;
    }
    &.danger {
        background-color: $error;
        border-color: $error;
        color: $white;
    }
    &.danger:hover {
        background-color: $grayed-error;
    }
}
