@import '../../Styles/vars';
.buttons-section {
    padding: 4px 0 4px 0;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    .status {
        font-size: 16px;
        font-weight: 600;
        color: $label-color;
    }
    .buttons {
        .mdi:before {
            font-size: 20px;
        }
        div {
            cursor: pointer;
            color: $primary;
            display: inline-flex;
            align-items: center;
            margin-bottom: -2px;
            span {
                border-bottom: 2px solid $primary;
            }
            i {
                margin-right: 5px;
            }
        }
        div:hover {
            color: lighten($primary, 15%);
            span {
                border-color: lighten($primary, 15%);
            }
        }
        .resubmit {
            i:before {
                transform: rotateY(180deg);
            }
        }
        span {
            margin-right: 15px;
        }
    }
}
