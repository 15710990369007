@import '../../../../../../../../Styles/vars';
.app-header {
    background: $white;
    height: 80px;
    border-bottom: 2px solid $grey;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}
