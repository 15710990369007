@import '../../Styles/vars';
.modal-background {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(75, 71, 101, 0.5);
    z-index: 1001;
    .modal {
        background: $white;
        box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
        min-width: 600px;
        color: $label-color;
        border-top-style: solid;
        border-top-width: 6px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        .modal-content {
            display: flex;
            flex-direction: column;
            margin: 25px;
            .close-button {
                align-self: flex-end;
                cursor: pointer;
            }
            h2 {
                font-size: 32px;
                color: $label-color;
                margin: 0 0 25px 0;
            }
            span {
                font-size: 18px;
            }
        }
        &.primary {
            border-top-color: $primary;
        }
        &.danger {
            border-top-color: $error;
        }
        .buttons {
            padding: 25px;
            background-color: $grey;
            text-align: right;
            #confirm {
                margin-left: 25px;
            }
        }
    }
    .modal:focus {
        outline: none;
    }
}
