@import '../../../../../../../../../../../../Styles/vars';
.cover-select {
    display: flex;
    .cover-image {
        max-width: 180px;
        max-height: 180px;
        margin-top: 25px;
        border-radius: $border-radius;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.4);
        background-color: $white;
    }
    .file-select {
        margin-top: 30px;
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        .select-heading {
            font-size: 16px;
            font-weight: 600;
            color: $primary;
        }
        .file-description {
            font-size: 12px;
            color: $label-color;
            margin-top: 5px;
            margin-bottom: 20px;
        }
        .button {
            display: flex;
            align-items: center;
            padding: 0 15px;
            &.disabled {
                color: $done;
                border-color: $done;
                cursor: default;
                pointer-events: none;
            }
        }
        .input {
            display: none;
        }
    }
}
