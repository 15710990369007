.mdi::before {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
}
.arrow-enter {
    .mdi::before {
        transform: rotateX(180deg);
    }
}
.arrow-enter-active {
    .mdi::before {
        transform: rotateX(0deg);
        transition: all 500ms ease-out;
    }
}
.arrow-exit {
    .mdi::before {
        transform: rotateX(0deg);
    }
}
.arrow-exit-active {
    .mdi::before {
        transform: rotateX(180deg);
        transition: all 500ms ease-out;
    }
}
.arrow-exit-done {
    .mdi::before {
        transform: rotateX(180deg);
    }
}

.arrow-down-enter {
    .mdi::before {
        transform: rotateX(0deg);
    }
}
.arrow-down-enter-active {
    .mdi::before {
        transform: rotateX(180deg);
        transition: all 500ms ease-out;
    }
}
.arrow-down-enter-done {
    .mdi::before {
        transform: rotateX(180deg);
    }
}
.arrow-down-exit {
    .mdi::before {
        transform: rotateX(180deg);
    }
}
.arrow-down-exit-active {
    .mdi::before {
        transform: rotateX(0deg);
        transition: all 500ms ease-out;
    }
}
.arrow-down-exit-done {
    .mdi::before {
        transform: rotateX(0deg);
    }
}
