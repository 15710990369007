@import '../../Styles/vars';
.section-header {
    width: 100%;
    height: 55px;
    border-bottom: 1px solid $border-grey;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: $primary;
    .title-wrapper {
        cursor: pointer;
        flex: 1 0 auto;
        .title {
            margin: 0 20px;
        }
    }
}
