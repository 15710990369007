@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
@import './Styles/vars';
body {
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', sans-serif;
}

h1 {
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    color: $primary;
}

h2 {
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: $primary;
}

h3 {
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    color: $dark-violet-text;
}

.container {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}

.status {
    .error {
        color: $error;
    }
    .review {
        color: $review;
    }
    .cool-off {
        color: $cool-off;
    }
    .automated-steps {
        color: $automated-steps;
    }
    .fulfillment {
        color: $fulfillment;
    }
    .done {
        color: $done;
    }
}

input,
select {
    height: 38px;
    padding: 0 0 0 15px;
    font-size: 16px;
    font-family: 'Source Sans Pro', sans-serif;
    border-radius: 3px;
    border: 1px solid $input-border;
    color: $dark-violet-text;
}

input:focus {
    box-shadow: 0 0 3px 2px rgba(0, 113, 188, 0.5);
    outline: none;
}

input:disabled {
    background-color: $grey;
    color: $done;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $done;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $done;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $done;
}
