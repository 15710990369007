@import '../../../../../../../../../../Styles/vars';
.footer-right {
    color: $grey-text;
    text-align: right;
    font-size: 13px;
    .company-name {
        font-weight: 600;
    }
    .links {
        a {
            text-decoration: none;
            color: $grey-text;
        }
        a:hover {
            color: $primary;
            font-weight: 600;
            transition-duration: 0.25s;
            transition-timing-function: ease-in;
        }
        a:not(:last-child) {
            margin-right: 10px;
        }
    }
}
