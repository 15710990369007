@import '../../../../../../../../Styles/vars';
.customer-content {
    background: $order-edit-background;
    border-bottom: 1px solid #dfdfdf;
    padding: 20px;
    .column {
        width: 335px;
        margin-bottom: 5px;
    }
    .column:not(:last-child) {
        margin-right: 30px;
    }
    .details-section {
        display: flex;
        width: 100%;
    }
    .delivery-method {
        font-size: 16px;
        font-weight: 600;
        color: $label-color;
        span {
            color: $primary;
        }
    }
    > *:not(:last-child) {
        border-bottom: 1px solid #dfdfdf;
        margin-bottom: 20px;
    }
}

.customer-content-enter {
    max-height: 0;
    overflow: hidden;
}

$max-height: 625px;
.customer-content-enter-active {
    max-height: $max-height;
    overflow: hidden;
    transition: all 500ms ease-in-out;
}

.customer-content-exit {
    max-height: $max-height;
    overflow: hidden;
}

.customer-content-exit-active {
    max-height: 0;
    overflow: hidden;
    transition: all 500ms ease-in-out;
}

.customer-content-exit-done {
    max-height: 0;
    overflow: hidden;
}
