@import '../../../../../../../../../../../../Styles/vars';
.radio-group {
    width: 100%;
    .label {
        color: $label-color;
        font-size: 16px;
        font-weight: 600;
    }
    .radio-buttons {
        display: flex;
        margin-top: 12px;
        margin-bottom: 20px;
        .radio-container:not(:last-child) {
            margin-right: 20px;
        }
    }
}
