@import '../../Styles/vars';
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 31px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $label-color;
    font-size: 15px;
    font-weight: 300;

    &--in-order-form {
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 25px;
    }

    &--disabled {
        cursor: auto;
        .checkmark {
            border-color: $input-border;
            background-color: $grey;
        }
    }
}

/* Hide the browser's default checkbox */

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: -2px;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: $border-radius;
    border: 1px solid $primary;
    background-color: $white;
}

/* When the checkbox is checked, add a blue background */

.checkbox-container input:checked ~ .checkmark {
    background-color: $primary;
}

.checkbox-container input:checked:disabled ~ .checkmark {
    background-color: $done;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */

.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */

.checkbox-container .checkmark:after {
    left: 6px;
    top: 3px;
    width: 3px;
    height: 7px;
    border: solid $white;
    border-width: 0 3px 3px 0;
    border-bottom-right-radius: $border-radius;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
