@import '../../../../../../../../../../Styles/vars';
.product-details {
    .inputs {
        width: 100%;
        .column {
            width: 335px;
        }
        .column:not(:last-child) {
            margin-right: 30px;
        }
        .radio-group.frame-buttons {
            margin-bottom: 38px;
        }
    }
}
