@import '../../../../Styles/vars';

.reason-select {
    &__label {
        color: $label-color;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
    }
}
