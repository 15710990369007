.inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    min-width: 750px;

    &__reason {
        flex: 1 0 auto;
        margin-right: 10px;
    }

    &__comment {
        flex: 2 0 auto;
    }
}
