@import '../../../../Styles/vars';
.filters {
    width: 250px;
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    margin-bottom: 30px;
    .filters-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        border-bottom: $filters-border;
        h3 {
            margin: 30px 0 20px 0;
        }
        .clear-all {
            font-size: 14px;
            font-weight: 600;
            color: $error;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
