@import '../../../../../../Styles/vars';
.page-size-select {
    grid-row: 1;
    grid-column: 1;
    justify-self: start;
    color: $dark-violet-text;
    display: flex;
    align-items: center;
    .mdi:before {
        color: $primary;
        margin-right: 5px;
    }
    .select {
        margin: 0 10px;
    }
}
