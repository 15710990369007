@import '../../../../../../../../Styles/vars';
.date-filter {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 300;
    color: $label-color;
    &:first-child {
        margin: 15px 0 10px 0;
    }
    &:last-child {
        margin-bottom: 15px;
    }
    .date-picker-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        i {
            color: $primary;
            position: absolute;
            left: 5px;
        }
        .mdi::before {
            font-size: 19px;
        }
        .react-datepicker-wrapper {
            .react-datepicker__input-container {
                display: flex;
                align-items: center;
                input {
                    height: 40px;
                    border-radius: $border-radius;
                    border: 1px solid #757575;
                    font-family: 'Source Sans Pro', sans-serif;
                    font-size: 16px;
                    padding-left: 35px;
                    max-width: 120px;
                }
                .react-datepicker__close-icon {
                    top: none;
                }
                .react-datepicker__close-icon:after {
                    background: none;
                    color: $label-color;
                    font-size: 16px;
                }
            }
        }
        .react-datepicker__day--selected {
            background-color: $primary;
        }
        .react-datepicker__day--keyboard-selected {
            color: #000;
            background-color: transparent;
        }
        .react-datepicker__day--keyboard-selected:hover {
            background-color: #f0f0f0;
        }
    }
}

.react-datepicker {
    font-family: 'Source Sans Pro', sans-serif;
}
