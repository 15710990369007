@import '../../Styles/vars';

.full-page-spinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: rgba($primary, 0.17);
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner {
        margin-bottom: -32px;
    }
}
