@import '../../../../../../../../Styles/vars';
.additional-info-panel {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border: 1px solid $info;
    border-radius: $border-radius;
    border-top-width: 6px;
    padding: 16px 20px;
    background: $white;
    margin-bottom: 15px;
    font-size: 16px;
    color: $label-color;
    &--warning {
        border-color: $review;
    }
    &--error {
        border-color: $error;
    }
    &__icon {
        margin-right: 10px;
    }
    &__messages {
        margin-top: 11px;
        p {
            word-break: break-all;
            hyphens: auto;
        }
        p:not(:first-child) {
            margin-top: 10px;
        }
    }
    &__icon {
        color: $info;
        &--warning {
            color: $review;
        }
        &--error {
            color: $error;
        }
    }
}

:global {
    .product-details {
        .mdi:before {
            font-size: 25px;
            font-weight: 300;
        }
    }
}
