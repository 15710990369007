@import '../../../../Styles/vars';
.orders {
    flex: 1 0 auto;
    margin-bottom: 30px;
    .ReactTable {
        border: none;
        .rt-thead {
            border-bottom: 2px solid $primary;
            font-weight: 600;
            font-size: 16px;
            color: $primary;
            .rt-tr {
                text-align: left;
            }
            .rt-th {
                border: none;
                outline: none;
                margin: 27px 0 17px 0;
            }
        }
        .rt-tbody {
            .clickable {
                cursor: pointer;
            }
            .rt-tr-group {
                border-bottom: none;
                .rt-tr {
                    color: $dark-violet-text;
                    border-top: none;
                    border-bottom: 1px solid $grey-violet;
                    font-size: 16px;
                    &.-even {
                        background-color: $grey-background;
                    }
                    &.-even:hover {
                        transition-duration: 0.2s;
                        background-color: #f1efff;
                    }
                    &.-odd {
                        background-color: $white;
                    }
                    &.-odd:hover {
                        transition-duration: 0.2s;
                        background-color: #f1efff;
                    }
                    .rt-td {
                        border-right: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        text-overflow: ellipsis;
                        * {
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    .date {
                        font-size: 14px;
                    }
                    .client-data {
                        a {
                            line-height: 16px;
                            font-size: 14px;
                            color: #0071bc;
                        }
                        a:hover {
                            text-decoration: underline;
                            color: #2a99dd;
                        }
                        .name {
                            line-height: 18px;
                        }
                    }
                    .post-code {
                        line-height: 18px;
                    }
                    .link {
                        .order-edit-link,
                        .order-edit-link:visited {
                            color: $primary;
                            text-decoration: none;
                        }
                        .order-edit-link:hover {
                            color: lighten($color: $primary, $amount: 20);
                            transition-duration: 0.2s;
                        }
                    }
                    .status {
                        .mdi {
                            color: #999999;
                            margin-right: 5px;
                            &:before {
                                font-size: 18px;
                            }
                        }
                        line-height: 18px;
                    }
                    .referenceNumber {
                        margin: 0 auto 0 0;
                        line-height: 18px;
                    }
                }
            }
        }
        .rt-noData {
            display: none;
        }
    }
}
