@import '../../Styles/vars';
.title-section {
    height: 72px;
    background-color: $grey;
    .container {
        justify-content: flex-start;
        h1:not(:first-child) {
            color: #5b5b5b;
            margin-left: 10px;
            font-weight: 300;
        }
        .orders-link {
            font-size: 24px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            color: $primary;
            text-decoration: none;
        }
    }
}
