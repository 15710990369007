@import '../../../../../../../../Styles/vars';
.comments-section-content {
    background: $order-edit-background;
    border-bottom: 1px solid #dfdfdf;
    padding: 20px;

    .ReactTable {
        border: none;
        max-height: 580px;
        .rt-thead {
            border-bottom: 2px solid $primary;
            font-weight: 600;
            font-size: 16px;
            color: $primary;
            .rt-tr {
                text-align: left;
            }
            .rt-th {
                border: none;
                outline: none;
                margin: 27px 0 17px 0;
            }
        }
        .rt-tbody {
            .rt-tr-group {
                border-bottom: none;
                .rt-tr {
                    color: $dark-violet-text;
                    border-top: none;
                    border-bottom: 1px solid $grey-violet;
                    font-size: 16px;
                    &.-even {
                        background-color: $grey-background;
                    }
                    &.-even:hover {
                        transition-duration: 0.2s;
                        background-color: #f1efff;
                    }
                    &.-odd {
                        background-color: $white;
                    }
                    &.-odd:hover {
                        transition-duration: 0.2s;
                        background-color: #f1efff;
                    }
                    .rt-td {
                        border-right: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        text-overflow: ellipsis;
                        * {
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    .date {
                        font-size: 14px;
                    }
                    .action {
                        line-height: 18px;
                    }
                }
            }
        }
        .rt-noData {
            display: none;
        }
    }
}

.comments-section-content-enter {
    max-height: 0;
    overflow: hidden;
}

$max-height: 625px;
.comments-section-content-enter-active {
    max-height: $max-height;
    overflow: hidden;
    transition: all 500ms ease-in-out;
}

.comments-section-content-exit {
    max-height: $max-height;
    overflow: hidden;
}

.comments-section-content-exit-active {
    max-height: 0;
    overflow: hidden;
    transition: all 500ms ease-in-out;
}

.comments-section-content-exit-done {
    max-height: 0;
    overflow: hidden;
}
