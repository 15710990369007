@import '../../../../../../../../../../Styles/vars';

.route-detail {
    font-size: 16px;
    font-weight: 600;
    color: $label-color;
    margin-bottom: 10px;

    &__title {
        font-size: 18px;
        font-weight: 600 !important;
    }

    &__bold {
        color: $primary;
        margin-left: 10px;
    }

    &__color {
        padding: 3px 12px;
        border-radius: 5px;
        margin-left: 5px;
    }
}
