@import '../../../../Styles/vars';
.search-input-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: -56px;
    margin-bottom: 16px;
    .search-input {
        flex: 1 0 auto;
        border-width: 1px 0 1px 1px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .button {
        border-radius: 0;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        width: 80px;
        padding: 0;
    }
    .button:active {
        box-shadow: 1px 0 3px 2px rgba(0, 113, 188, 0.5);
    }
}
