@import '../../../../../../../../Styles/vars';
.product-content {
    .product-details {
        background: $order-edit-background;
        padding: 20px;
        border-bottom: 1px solid $form-border;

        .section-separator {
            border-bottom: 1px solid $form-border;
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
    }
}

.product-content-enter {
    max-height: 0;
    overflow: hidden;
}

$max-height: 625px;
.product-content-enter-active {
    max-height: $max-height;
    overflow: hidden;
    transition: all 500ms ease-in-out;
}

.product-content-exit {
    max-height: $max-height;
    overflow: hidden;
}

.product-content-exit-active {
    max-height: 0;
    overflow: hidden;
    transition: all 500ms ease-in-out;
}

.product-content-exit-done {
    max-height: 0;
    overflow: hidden;
}
