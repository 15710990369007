@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
@import '../../../../../../Styles/vars';
.redux-toastr {
    font-family: 'Source Sans Pro', sans-serif;
    width: 500px;
    .bottom-right {
        width: auto;
        max-width: 550px;
        min-width: 300px;
        line-height: 20px;
    }
    .toastr {
        min-height: auto;
        color: $white;
        width: auto;
        height: auto;
        .rrt-right-container {
            button {
                color: $white;
                opacity: 1;
            }
        }
        .rrt-left-container {
            display: none;
        }
        .rrt-middle-container {
            width: 90%;
            padding: 10px 0 10px 10px;
            margin-left: 0;
        }
        &.rrt-info {
            background-color: $info;
        }
        &.rrt-error {
            background-color: $error;
        }
    }
}
