@import '../../../../../../Styles/vars';

.form-buttons {
    height: 100px;
    background-color: $grey;
    text-align: right;
    border-top: 1px solid $form-border;
    padding: 0 20px;
    .button {
        width: 165px;
        margin: 30px 0;
    }
    .button:not(:last-child) {
        margin-right: 15px;
    }
}
