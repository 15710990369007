@import '../../../../../../../../../../Styles/vars';
.user-dropdown {
    color: $primary;
    position: relative;
    .dropdown-header {
        min-width: 100px;
        height: 40px;
        border-radius: $border-radius;
        border: $default-border;
        padding: 0 15px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px 0;
        .mdi::before {
            font-weight: 600;
            font-size: 16px;
            line-height: 1.5;
        }
    }
    .items {
        position: absolute;
        top: 50px;
        right: 0;
        min-width: 10rem;
        padding: 0.5rem 0;
        margin: 0.125rem 0 0;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        .item {
            height: 30px;
            background: $white;
            display: flex;
            align-items: center;
            padding-left: 15px;
            width: calc(100% - 16px);
            color: #333333;
            cursor: pointer;
        }
        .item:hover {
            color: #16181b;
            text-decoration: none;
            background-color: #f8f9fa;
        }
    }
}
