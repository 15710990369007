.inputs {
    display: flex;
    .column {
        width: 335px;
        margin-right: 30px;
    }
    .column:last-child {
        margin-right: 0;
    }
}
