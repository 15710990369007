@import '../../../../../../Styles/vars';

.session-checking {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    .logo {
        width: auto;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 40px 40px;
        $animation: bump 1.5s infinite;
        .rectangle {
            width: 40px;
            height: 40px;
            box-shadow: 2px 2px 15px -2px $primary;
        }
        .first {
            background: #f05125;
            animation: $animation;
        }
        .second {
            background: #fdb813;
            animation: $animation;
            animation-delay: 150ms;
        }
        .third {
            background: #32a0da;
            animation: $animation;
            animation-delay: 550ms;
        }
        .fourth {
            background: #7fbb42;
            animation: $animation;
            animation-delay: 350ms;
        }
        @keyframes bump {
            25% {
                transform: scale(1.35, 1.35) translate(-7px, -7px);
                box-shadow: 5px 4px 10px -2px $primary;
            }
        }
    }
    h1 {
        font-weight: 300;
    }
}
