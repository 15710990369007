@import '../../../../../../Styles/vars';
.filters-section-header {
    border-bottom: $filters-border;
    color: $primary;
    font-weight: 600;
    font-size: 16px;
    height: 54px;
    padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.filters-section {
    background: $filters-section-background;
    padding: 0 15px;
}

.filters-section-enter {
    max-height: 0;
    overflow: hidden;
}

.filters-section-enter-active {
    max-height: 300px;
    overflow: hidden;
    transition: all 500ms ease-in-out;
}

.filters-section-exit {
    max-height: 300px;
    overflow: hidden;
}

.filters-section-exit-active {
    max-height: 0;
    overflow: hidden;
    transition: all 500ms ease-in-out;
}

.filters-section-exit-done {
    max-height: 0;
    overflow: hidden;
}
