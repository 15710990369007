$primary: #453c90;
$grayed-primary: #746cac;
$white: #ffffff;
$grey: #f5f5f5;
$grey-text: #7c7c7c;
$grey-violet: #dddddd;
$grey-background: #f6f5f9;
$dark-violet-text: #333333;
$error: #c63d54;
$grayed-error: #d56073;
$review: #ffb000;
$cool-off: $primary;
$automated-steps: #0071bc;
$fulfillment: #118011;
$done: #999999;
$border-grey: #e2e2e2;
$label-color: #666666;
$filters-section-background: #fafafa;
$info: #2979af;
$form-border: #dfdfdf;
$order-edit-background: rgba(245, 245, 245, 0.5);
$input-border: #757575;

$default-border: 1px solid $primary;
$filters-border: 1px solid $border-grey;
$border-radius: 3px;
