.filters-section {
    .checkbox-container:first-child {
        margin-top: 15px;
    }

    .checkbox-container:last-child {
        margin-bottom: 15px;
    }

    .checkbox-container:not(:last-child) {
        margin-bottom: 14px;
    }
}
