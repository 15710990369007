@import '../../Styles/vars';
.form-input {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
        margin-bottom: 5px;
    }

    &--empty {
        select {
            color: $done;
        }
    }
    &__label {
        color: $label-color;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    &__error-message {
        height: 18px;
        font-size: 14px;
        padding-top: 2px;
        color: $error;
    }

    &__option:hover {
        background-color: purple;
        outline: none;
    }
    &__option {
        color: $dark-violet-text;
    }
    &__option--placeholder {
        color: $done;
    }
}
